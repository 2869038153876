import React, { useState } from 'react';
import { Route, Switch, Redirect, Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import Dashboard from './components/Dashboard';
import CampaignAnalysis from './components/CampaignAnalysis';
import FileUpload from './components/FileUpload';
import LoadingOverlay from './components/LoadingOverlay';
import SearchTermsView from './components/SearchTermsView';
import WastedSpendAnalysis from './components/WastedSpendAnalysis';
import OpportunitiesPanel from './components/OpportunitiesPanel';

const App: React.FC = () => {
  const [showSearchTermsOnly, setShowSearchTermsOnly] = useState(false);
  
  const { loading, hasData, activeReportType } = useSelector((state: RootState) => state.campaign);

  return (
    <div className="min-h-screen bg-gray-100">
      {loading && <LoadingOverlay />}
      <nav className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between min-h-[120px]">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center px-2 min-h-[120px] min-w-[200px]">
                <Link to="/" className="flex items-center min-h-[120px]">
                  <img src="/PPC.png" alt="PPC Analyzer" className="h-[100px] w-auto max-w-none" style={{ minHeight: '180px' }} />
                </Link>
              </div>
              <div className="hidden sm:ml-10 sm:flex sm:space-x-1 sm:items-center">
                <NavLink
                  to="/"
                  exact
                  className="group relative inline-flex items-center px-6 py-2.5 text-sm font-medium text-gray-700 rounded-lg transition-all duration-200 ease-in-out hover:text-[#0088ff] mx-2 hover:bg-blue-50"
                  activeClassName="bg-blue-50 text-[#0088ff] shadow-sm transform scale-102"
                >
                  <span className="relative z-10">Dashboard</span>
                  <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-[#0088ff20] to-[#0066cc10] opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
                </NavLink>
                <NavLink
                  to="/campaign-analysis"
                  className="group relative inline-flex items-center px-6 py-2.5 text-sm font-medium text-gray-700 rounded-lg transition-all duration-200 ease-in-out hover:text-[#0088ff] mx-2 hover:bg-blue-50"
                  activeClassName="bg-blue-50 text-[#0088ff] shadow-sm transform scale-102"
                >
                  <span className="relative z-10">Campaign Analysis</span>
                  <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-[#0088ff20] to-[#0066cc10] opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
                </NavLink>
                <NavLink
                  to="/search-terms"
                  className="group relative inline-flex items-center px-6 py-2.5 text-sm font-medium text-gray-700 rounded-lg transition-all duration-200 ease-in-out hover:text-[#0088ff] mx-2 hover:bg-blue-50"
                  activeClassName="bg-blue-50 text-[#0088ff] shadow-sm transform scale-102"
                >
                  <span className="relative z-10">Search Terms</span>
                  <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-[#0088ff20] to-[#0066cc10] opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
                </NavLink>
                <NavLink
                  to="/opportunities"
                  className="group relative inline-flex items-center px-6 py-2.5 text-sm font-medium text-gray-700 rounded-lg transition-all duration-200 ease-in-out hover:text-[#0088ff] mx-2 hover:bg-blue-50"
                  activeClassName="bg-blue-50 text-[#0088ff] shadow-sm transform scale-102"
                >
                  <span className="relative z-10">Opportunities</span>
                  <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-[#0088ff20] to-[#0066cc10] opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
                </NavLink>
                <NavLink
                  to="/wasted-spend"
                  className="group relative inline-flex items-center px-6 py-2.5 text-sm font-medium text-gray-700 rounded-lg transition-all duration-200 ease-in-out hover:text-[#0088ff] mx-2 hover:bg-blue-50"
                  activeClassName="bg-blue-50 text-[#0088ff] shadow-sm transform scale-102"
                >
                  <span className="relative z-10">Wasted Spend</span>
                  <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-[#0088ff20] to-[#0066cc10] opacity-0 group-hover:opacity-100 transition-opacity duration-200"></div>
                </NavLink>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <FileUpload reportType={activeReportType} />
            </div>
          </div>
        </div>
      </nav>

      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Switch>
            <Route 
              exact 
              path="/" 
              render={() => hasData ? (
                <Dashboard 
                  showSearchTermsOnly={showSearchTermsOnly}
                  setShowSearchTermsOnly={setShowSearchTermsOnly}
                />
              ) : (
                <div className="max-w-3xl mx-auto">
                  <FileUpload reportType="searchTerm" fullPage={true} />
                </div>
              )} 
            />
            <Route
              path="/campaign-analysis"
              render={() => hasData ? (
                <CampaignAnalysis 
                  showSearchTermsOnly={showSearchTermsOnly}
                  setShowSearchTermsOnly={setShowSearchTermsOnly}
                />
              ) : (
                <Redirect to="/" />
              )}
            />
            <Route
              path="/search-terms"
              render={() => hasData ? (
                <SearchTermsView />
              ) : (
                <Redirect to="/" />
              )}
            />
            <Route
              path="/opportunities"
              render={() => hasData ? (
                <OpportunitiesPanel />
              ) : (
                <Redirect to="/" />
              )}
            />
            <Route
              path="/wasted-spend"
              render={() => hasData ? (
                <WastedSpendAnalysis />
              ) : (
                <Redirect to="/" />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default App;
