import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setData } from '../store/campaignSlice';
import * as XLSX from 'xlsx';
import { ReportType } from '../types/reports';

interface Props {
  reportType: ReportType;
  fullPage?: boolean;
}

const FileUpload: React.FC<Props> = ({ reportType, fullPage = false }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const normalizeColumnName = (name: string): string => {
    // Remove quotes and trim whitespace
    name = name.replace(/"/g, '').trim();
    
    // Map Excel column names to our expected format
    const columnMap: { [key: string]: string } = {
      'Campaign Name': 'campaignName',
      'Customer Search Term': 'customerSearchTerm',
      'Spend': 'spend',
      'Clicks': 'clicks',
      'Impressions': 'impressions',
      '7 Day Total Sales': 'sevenDayTotalSales',
      '7 Day Total Sales ': 'sevenDayTotalSales',
      '7 Day Total Orders (#)': 'sevenDayTotalOrders',
      'Start Date': 'startDate',
      'End Date': 'endDate',
      'Click-Thru Rate (CTR)': 'ctr',
      'Cost Per Click (CPC)': 'cpc',
      'Total Return on Advertising Spend (ROAS)': 'roas',
      'Ad Group Name': 'adGroupName',
      'Match Type': 'matchType',
      'Targeting': 'targeting',
      '7 Day Advertised SKU Sales': 'sevenDayAdvertisedSkuSales',
      '7 Day Advertised SKU Sales ': 'sevenDayAdvertisedSkuSales',
      '7 Day Other SKU Sales': 'sevenDayOtherSkuSales',
      '7 Day Other SKU Sales ': 'sevenDayOtherSkuSales',
      'Portfolio name': 'portfolioName',
      'Portfolio Name': 'portfolioName',
      '7 Day Advertised SKU Units (#)': 'sevenDayAdvertisedSkuUnits',
      '7 Day Conversion Rate': 'sevenDayConversionRate',
      '7 Day Other SKU Units (#)': 'sevenDayOtherSkuUnits',
      '7 Day Total Units (#)': 'sevenDayTotalUnits',
      'Currency': 'currency'
    };

    return columnMap[name] || name;
  };

  const processFile = async (file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        
        if (jsonData.length === 0) {
          throw new Error('Spreadsheet is empty');
        }

        const normalizedData = jsonData.map((row: any) => {
          const normalizedRow: any = {};
          Object.entries(row).forEach(([key, value]) => {
            const normalizedKey = normalizeColumnName(key);
            
            if (normalizedKey === 'spend' || normalizedKey === 'sevenDayTotalSales') {
              normalizedRow[normalizedKey] = Number(value) || 0;
            } else if (normalizedKey === 'startDate' || normalizedKey === 'endDate') {
              if (typeof value === 'number') {
                const date = new Date(Math.round((value - 25569) * 86400 * 1000));
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                normalizedRow[normalizedKey] = `${year}-${month}-${day}`;
              } else {
                normalizedRow[normalizedKey] = value;
              }
            } else if (['clicks', 'impressions', 'sevenDayTotalOrders'].includes(normalizedKey)) {
              normalizedRow[normalizedKey] = Number(value) || 0;
            } else {
              normalizedRow[normalizedKey] = value;
            }
          });

          return normalizedRow;
        });

        dispatch(setData({ type: reportType, data: normalizedData }));
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      console.error('Error processing file:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files?.[0];
    if (file) {
      processFile(file);
    }
  };

  if (fullPage) {
    return (
      <div 
        className={`flex flex-col items-center justify-center p-10 border-2 border-dashed rounded-lg transition-colors
          ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-white'}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{ minHeight: '400px' }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".xlsx,.xls,.csv"
          className="hidden"
        />
        <div className="text-center space-y-4">
          <div className="flex justify-center">
            <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
          </div>
          <h3 className="text-lg font-medium text-gray-900">
            {isDragging ? 'Drop your file here' : 'Upload your PPC report'}
          </h3>
          <p className="text-gray-500">Drag and drop your file here, or click to select</p>
          <button
            onClick={handleClick}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Select File
          </button>
          <p className="text-sm text-gray-500">Supports .xlsx, .xls, and .csv files</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".xlsx,.xls,.csv"
        className="hidden"
      />
      <button
        onClick={handleClick}
        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Upload Report
      </button>
    </>
  );
};

export default FileUpload;
