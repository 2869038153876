import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportType } from '../types/reports';

interface CampaignMetrics {
  overallAcos: number;
  overallRoas: number;
  averageCpc: number;
  conversionRate: number;
  ctr: number;
  totalSpend: number;
  totalSales: number;
  spendTrend: number;
  salesTrend: number;
  acosTrend: number;
  roasTrend: number;
  wastedSpend: number;
  wastedAcos: number;
  totalOrders: number;
}

interface Recommendation {
  id: string;
  type: string;
  priority: 'high' | 'medium' | 'low';
  message: string;
  metrics: {
    current: number;
    target: number;
    unit: string;
  };
  campaign: string;
  action: string;
}

interface CampaignState {
  data: {
    [key in ReportType]: any[];
  };
  hasData: boolean;
  loading: boolean;
  error: string | null;
  activeReportType: ReportType;
  activeView: string;
  metrics: CampaignMetrics | null;
  startDate: string | null;
  endDate: string | null;
  recommendations: Recommendation[];
}

const initialState: CampaignState = {
  data: {
    searchTerm: [],
    placement: [],
    targeting: [],
    advertisedProduct: [],
    sponsoredBrandsAttributedPurchases: [],
    sponsoredBrandsKeyword: [],
    sponsoredBrandsSearchTerm: [],
    sponsoredBrandsPlacement: [],
    sponsoredBrandsImpressionShare: []
  },
  hasData: false,
  loading: false,
  error: null,
  activeReportType: 'searchTerm',
  activeView: 'dashboard',
  metrics: null,
  startDate: null,
  endDate: null,
  recommendations: []
};

function calculateMetrics(data: any[]): CampaignMetrics {
  let totalSpend = 0;
  let totalSales = 0;
  let totalClicks = 0;
  let totalImpressions = 0;
  let totalOrders = 0;
  let wastedSpend = 0;

  console.log('Calculating metrics for data:', data.length, 'items');

  // First pass: calculate totals
  data.forEach((item, index) => {
    const spend = Number(item.spend) || 0;
    const sales = Number(item.sevenDayTotalSales) || 0;
    const clicks = Number(item.clicks) || 0;
    const impressions = Number(item.impressions) || 0;
    const orders = Number(item.sevenDayTotalOrders) || 0;

    totalSpend += spend;
    totalSales += sales;
    totalClicks += clicks;
    totalImpressions += impressions;
    totalOrders += orders;

    // Track wasted spend (spend with no sales)
    if (sales === 0 && spend > 0) {
      wastedSpend += spend;
    }

    if (index === 0) {
      console.log('Sample metrics calculation:', {
        spend,
        sales,
        clicks,
        impressions,
        orders
      });
    }
  });

  const overallAcos = totalSales > 0 ? (totalSpend / totalSales) * 100 : 0;
  const overallRoas = totalSpend > 0 ? totalSales / totalSpend : 0;
  const averageCpc = totalClicks > 0 ? totalSpend / totalClicks : 0;
  const conversionRate = totalClicks > 0 ? (totalOrders / totalClicks) * 100 : 0;
  const ctr = totalImpressions > 0 ? (totalClicks / totalImpressions) * 100 : 0;

  console.log('Calculated metrics:', {
    totalSpend,
    totalSales,
    overallAcos,
    overallRoas,
    wastedSpend
  });

  return {
    overallAcos,
    overallRoas,
    averageCpc,
    conversionRate,
    ctr,
    totalSpend,
    totalSales,
    spendTrend: 0,
    salesTrend: 0,
    acosTrend: 0,
    roasTrend: 0,
    wastedSpend,
    wastedAcos: totalSales > 0 ? (wastedSpend / totalSales) * 100 : 0,
    totalOrders
  };
}

function generateRecommendations(data: any[]): Recommendation[] {
  const recommendations: Recommendation[] = [];
  let id = 1;

  console.log('Generating recommendations for:', data.length, 'items');

  data.forEach((item, index) => {
    const spend = Number(item.spend) || 0;
    const sales = Number(item.sevenDayTotalSales) || 0;
    const clicks = Number(item.clicks) || 0;
    const orders = Number(item.sevenDayTotalOrders) || 0;
    const acos = sales > 0 ? (spend / sales) * 100 : Infinity;
    const conversionRate = clicks > 0 ? (orders / clicks) * 100 : 0;

    // High ACoS Campaigns
    if (acos > 50 && spend > 20) {
      recommendations.push({
        id: `high-acos-${id++}`,
        type: 'high-acos',
        priority: 'high',
        message: `Campaign "${item.campaignName}" has a high ACoS of ${acos.toFixed(1)}%`,
        metrics: {
          current: acos,
          target: 35,
          unit: '%'
        },
        campaign: item.campaignName,
        action: 'Review bid strategy and targeting'
      });
    }

    // Low Conversion Rate
    if (conversionRate < 2 && clicks > 30) {
      recommendations.push({
        id: `low-conv-${id++}`,
        type: 'low-conversion',
        priority: 'medium',
        message: `Campaign "${item.campaignName}" has a low conversion rate of ${conversionRate.toFixed(1)}%`,
        metrics: {
          current: conversionRate,
          target: 5,
          unit: '%'
        },
        campaign: item.campaignName,
        action: 'Optimize product listing and targeting'
      });
    }

    // Zero Sales with Significant Spend
    if (sales === 0 && spend > 10) {
      recommendations.push({
        id: `no-sales-${id++}`,
        type: 'no-sales',
        priority: 'high',
        message: `Campaign "${item.campaignName}" has spent $${spend.toFixed(2)} with no sales`,
        metrics: {
          current: 0,
          target: 1,
          unit: 'sales'
        },
        campaign: item.campaignName,
        action: 'Pause campaign and review targeting'
      });
    }
  });

  console.log('Generated recommendations:', recommendations.length);
  return recommendations;
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<{ type: ReportType; data: any[] }>) => {
      console.log('Setting data in Redux:', {
        type: action.payload.type,
        dataLength: action.payload.data.length,
        firstRow: action.payload.data[0],
        sample: action.payload.data.slice(0, 3)
      });

      state.data[action.payload.type] = action.payload.data;
      state.hasData = action.payload.data.length > 0;
      
      const newMetrics = calculateMetrics(action.payload.data);
      console.log('Calculated new metrics:', newMetrics);
      state.metrics = newMetrics;
      
      state.error = null;
      state.activeReportType = action.payload.type;
      state.recommendations = generateRecommendations(action.payload.data);

      // Set initial date range if not already set
      if (!state.startDate || !state.endDate) {
        const dates = action.payload.data
          .map(item => item.startDate)
          .filter(Boolean)
          .sort();
        if (dates.length > 0) {
          state.startDate = dates[0];
          state.endDate = dates[dates.length - 1];
        }
      }

      console.log('Redux state after update:', {
        hasData: state.hasData,
        activeReportType: state.activeReportType,
        dataLength: state.data[action.payload.type].length,
        metrics: state.metrics
      });
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      console.log('Setting error in Redux:', action.payload);
      state.error = action.payload;
      if (action.payload) {
        state.loading = false;
      }
    },
    setActiveReportType: (state, action: PayloadAction<ReportType>) => {
      state.activeReportType = action.payload;
      if (state.data[action.payload].length > 0) {
        const newMetrics = calculateMetrics(state.data[action.payload]);
        state.metrics = newMetrics;
        state.recommendations = generateRecommendations(state.data[action.payload]);
      }
    },
    setActiveView: (state, action: PayloadAction<string>) => {
      state.activeView = action.payload;
    },
    setDateRange: (state, action: PayloadAction<{ startDate?: string; endDate?: string }>) => {
      if (action.payload.startDate) {
        state.startDate = action.payload.startDate;
      }
      if (action.payload.endDate) {
        state.endDate = action.payload.endDate;
      }
    },
    resetState: (state) => {
      state.data = initialState.data;
      state.hasData = false;
      state.loading = false;
      state.error = null;
      state.activeReportType = 'searchTerm';
      state.metrics = null;
      state.startDate = null;
      state.endDate = null;
      state.recommendations = [];
    }
  },
});

export const {
  setData,
  setLoading,
  setError,
  setActiveReportType,
  setActiveView,
  setDateRange,
  resetState
} = campaignSlice.actions;

export default campaignSlice.reducer;
