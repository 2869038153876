import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

interface OpportunityFilters {
  minSpend: string;
  minSales: string;
  highAcosThreshold: string;
  mediumAcosThreshold: string;
  lowCtrThreshold: string;
  lowCvrThreshold: string;
  campaignName: string;
  selectedTypes: string[];
  selectedPriorities: string[];
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

interface Recommendation {
  id: string;
  title: string;
  type: string;
  priority: 'high' | 'medium' | 'low';
  message: string;
  metrics: {
    current: number;
    target: number;
    unit: string;
  };
  campaign: string;
  action: string;
  spend: number;
  sales: number;
  acos: number;
  roas: number;
  ctr?: number;
  cvr?: number;
  cpc?: number;
}

const initialFilters: OpportunityFilters = {
  minSpend: '0',
  minSales: '0',
  highAcosThreshold: '50',
  mediumAcosThreshold: '35',
  lowCtrThreshold: '0.3',
  lowCvrThreshold: '1',
  campaignName: '',
  selectedTypes: [],
  selectedPriorities: [],
  sortBy: 'spend',
  sortOrder: 'desc',
};

const OpportunitiesPanel: React.FC = () => {
  const { data, activeReportType } = useSelector((state: RootState) => state.campaign);
  const [filters, setFilters] = useState<OpportunityFilters>(initialFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Generate recommendations
  const recommendations = useMemo(() => {
    if (!data || !data[activeReportType] || data[activeReportType].length === 0) return [];

    // Group and aggregate data by campaign name first
    const aggregatedData = data[activeReportType].reduce((acc: { [key: string]: any }, item: any) => {
      const campaignName = (item.campaignName || '').trim();
      const key = campaignName.toLowerCase(); // Case-insensitive matching
      
      if (!acc[key]) {
        acc[key] = {
          campaignName, // Keep original case
          spend: 0,
          sevenDayTotalSales: 0,
          clicks: 0,
          impressions: 0,
          sevenDayTotalOrders: 0
        };
      }
      
      acc[key].spend += Number(item.spend) || 0;
      acc[key].sevenDayTotalSales += Number(item.sevenDayTotalSales) || 0;
      acc[key].clicks += Number(item.clicks) || 0;
      acc[key].impressions += Number(item.impressions) || 0;
      acc[key].sevenDayTotalOrders += Number(item.sevenDayTotalOrders) || 0;
      
      return acc;
    }, {});

    const results: Recommendation[] = [];

    // Use aggregated data for recommendations
    Object.values(aggregatedData).forEach((campaign: any) => {
      const spend = campaign.spend;
      const sales = campaign.sevenDayTotalSales;
      const clicks = campaign.clicks;
      const impressions = campaign.impressions;
      const orders = campaign.sevenDayTotalOrders;

      const metrics = {
        acos: sales > 0 ? (spend / sales) * 100 : 0,
        ctr: impressions > 0 ? (clicks / impressions) * 100 : 0,
        cvr: clicks > 0 ? (orders / clicks) * 100 : 0,
        roas: spend > 0 ? sales / spend : 0,
        cpc: clicks > 0 ? spend / clicks : 0,
        sales: sales,
        spend: spend
      };

      // Only process campaigns with significant spend
      if (spend > 50) {
        const issues = [];
        let priority: 'high' | 'medium' | 'low' = 'low';

        // Check ACoS thresholds
        if (metrics.acos > parseFloat(filters.highAcosThreshold)) {
          issues.push(`High ACoS (${metrics.acos.toFixed(1)}%)`);
          priority = 'high';
        } else if (metrics.acos > parseFloat(filters.mediumAcosThreshold)) {
          issues.push(`Medium ACoS (${metrics.acos.toFixed(1)}%)`);
          priority = 'medium';
        }

        // Check CTR and CVR
        if (metrics.ctr < parseFloat(filters.lowCtrThreshold)) {
          issues.push(`Low CTR (${metrics.ctr.toFixed(2)}%)`);
          priority = priority === 'low' ? ('medium' as const) : priority;
        }
        if (metrics.cvr < parseFloat(filters.lowCvrThreshold)) {
          issues.push(`Low CVR (${metrics.cvr.toFixed(2)}%)`);
          priority = priority === 'low' ? ('medium' as const) : priority;
        }

        if (issues.length > 0) {
          results.push({
            id: `performance-${campaign.campaignName}`,
            title: 'Performance Optimization',
            type: 'performance',
            priority,
            message: `Campaign "${campaign.campaignName}" needs optimization:\n- ${issues.join('\n- ')}`,
            metrics: {
              current: metrics.acos,
              target: parseFloat(filters.mediumAcosThreshold),
              unit: '%'
            },
            campaign: campaign.campaignName,
            action: 'Review campaign settings and targeting',
            ...metrics
          });
        }

        // Only add sales recommendation if not already flagged for high ACoS
        if (sales < spend && !issues.includes(`High ACoS (${metrics.acos.toFixed(1)}%)`)) {
          results.push({
            id: `sales-${campaign.campaignName}`,
            title: 'Low Sales Performance',
            type: 'sales',
            priority: 'high',
            message: `Campaign has low ROAS (${metrics.roas.toFixed(2)})`,
            metrics: {
              current: metrics.roas,
              target: 1,
              unit: 'ratio'
            },
            campaign: campaign.campaignName,
            action: 'Review targeting and bid strategy',
            ...metrics
          });
        }
      }

      // High potential opportunity (good ROAS but low spend)
      if (metrics.roas > 3 && spend < 50 && spend > 0) {
        results.push({
          id: `potential-${campaign.campaignName}`,
          title: 'Growth Opportunity',
          type: 'potential',
          priority: 'medium',
          message: `Strong ROAS (${metrics.roas.toFixed(2)}) with low spend`,
          metrics: {
            current: spend,
            target: 50,
            unit: '$'
          },
          campaign: campaign.campaignName,
          action: 'Consider increasing budget',
          ...metrics
        });
      }
    });

    return results;
  }, [data, activeReportType, filters]);

  // Filter and sort recommendations
  const filteredRecommendations = useMemo(() => {
    return recommendations
      .filter((rec) => {
        const minSpend = parseFloat(filters.minSpend) || 0;
        const minSales = parseFloat(filters.minSales) || 0;
        const typeMatch = filters.selectedTypes.length === 0 || filters.selectedTypes.includes(rec.type);
        const priorityMatch = filters.selectedPriorities.length === 0 || filters.selectedPriorities.includes(rec.priority);
        
        return rec.spend >= minSpend && 
               rec.sales >= minSales && 
               typeMatch && 
               priorityMatch;
      })
      .sort((a, b) => {
        // Sort by priority first
        const priorityOrder = { high: 0, medium: 1, low: 2 };
        const priorityDiff = priorityOrder[a.priority] - priorityOrder[b.priority];
        if (priorityDiff !== 0) return priorityDiff;
        
        // Then by spend
        return b.spend - a.spend;
      });
  }, [recommendations, filters]);

  // Pagination
  const totalPages = Math.ceil(filteredRecommendations.length / itemsPerPage);
  const paginatedRecommendations = filteredRecommendations.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleFilterChange = (key: keyof OpportunityFilters, value: string | string[]) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setFilters(initialFilters);
    setCurrentPage(1);
  };

  const getPriorityColor = (priority: 'high' | 'medium' | 'low', type: string) => {
    if (type === 'potential') {
      return 'bg-purple-100 border-purple-500';
    }
    switch (priority) {
      case 'high':
        return 'bg-red-100 border-red-500';
      case 'medium':
        return 'bg-yellow-100 border-yellow-500';
      default:
        return 'bg-green-100 border-green-500';
    }
  };

  const getPriorityBadgeColor = (priority: 'high' | 'medium' | 'low', type: string) => {
    if (type === 'potential') {
      return 'bg-purple-500 text-white';
    }
    switch (priority) {
      case 'high':
        return 'bg-red-500 text-white';
      case 'medium':
        return 'bg-yellow-500 text-white';
      default:
        return 'bg-green-500 text-white';
    }
  };
  
  return (
    <div className="opportunities-panel p-4">
      {/* Filter Banner */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Filters</h2>
          <button
            onClick={resetFilters}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            Reset Filters
          </button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Thresholds Section */}
          <div className="space-y-2">
            <h3 className="font-medium mb-2">Thresholds</h3>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={filters.highAcosThreshold}
                onChange={(e) => handleFilterChange('highAcosThreshold', e.target.value)}
                className="w-20 px-2 py-1 border rounded"
                placeholder="High"
              />
              <span className="text-sm">High ACoS %</span>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={filters.mediumAcosThreshold}
                onChange={(e) => handleFilterChange('mediumAcosThreshold', e.target.value)}
                className="w-20 px-2 py-1 border rounded"
                placeholder="Medium"
              />
              <span className="text-sm">Medium ACoS %</span>
            </div>
          </div>

          {/* Minimum Values Section */}
          <div className="space-y-2">
            <h3 className="font-medium mb-2">Minimum Values</h3>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={filters.minSpend}
                onChange={(e) => handleFilterChange('minSpend', e.target.value)}
                className="w-20 px-2 py-1 border rounded"
                placeholder="0"
              />
              <span className="text-sm">Min Spend ($)</span>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="number"
                value={filters.minSales}
                onChange={(e) => handleFilterChange('minSales', e.target.value)}
                className="w-20 px-2 py-1 border rounded"
                placeholder="0"
              />
              <span className="text-sm">Min Sales ($)</span>
            </div>
          </div>

          {/* Types and Priorities Section */}
          <div className="space-y-2">
            <h3 className="font-medium mb-2">Categories</h3>
            <div className="flex flex-wrap gap-2">
              {['performance', 'sales', 'potential'].map((type) => (
                <label key={type} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={filters.selectedTypes.includes(type)}
                    onChange={(e) => {
                      const newTypes = e.target.checked
                        ? [...filters.selectedTypes, type]
                        : filters.selectedTypes.filter((t) => t !== type);
                      handleFilterChange('selectedTypes', newTypes);
                    }}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2 text-sm capitalize">{type}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Recommendations List */}
      <div className="recommendations-grid grid gap-4 mt-4">
        {paginatedRecommendations.map((rec) => (
          <div
            key={rec.id}
            className={`recommendation-card p-4 rounded-lg shadow-md border-l-4 ${getPriorityColor(
              rec.priority,
              rec.type
            )}`}
          >
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="text-lg font-semibold">{rec.title}</h3>
                <div className="text-sm font-medium">{rec.campaign}</div>
              </div>
              <div className={`priority-badge px-2 py-1 rounded text-xs font-semibold ${getPriorityBadgeColor(rec.priority, rec.type)}`}>
                {rec.priority.toUpperCase()}
              </div>
            </div>

            <div className="message-section my-2">
              <p className="text-sm">{rec.message}</p>
            </div>

            <div className="metrics-grid grid grid-cols-2 gap-2 mt-3">
              <div className="metric-item">
                <span className="text-xs text-gray-600">Spend</span>
                <div className="text-sm font-medium">${rec.spend.toFixed(2)}</div>
              </div>
              <div className="metric-item">
                <span className="text-xs text-gray-600">Sales</span>
                <div className="text-sm font-medium">${rec.sales.toFixed(2)}</div>
              </div>
              <div className="metric-item">
                <span className="text-xs text-gray-600">ROAS</span>
                <div className="text-sm font-medium">{rec.roas?.toFixed(2) || '0.00'}</div>
              </div>
              <div className="metric-item">
                <span className="text-xs text-gray-600">ACoS</span>
                <div className="text-sm font-medium">{rec.acos?.toFixed(1) || '0.0'}%</div>
              </div>
            </div>

            <div className="action-section mt-3 pt-2 border-t border-gray-200">
              <div className="text-sm font-medium text-blue-600">{rec.action}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center items-center gap-4 mt-6">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default OpportunitiesPanel;
