import React from 'react';

const LoadingOverlay: React.FC = () => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-8 shadow-2xl max-w-sm w-full mx-4">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-600"></div>
          <h2 className="mt-4 text-xl font-semibold text-gray-900">Processing Data</h2>
          <p className="mt-2 text-gray-600 text-center">
            Please wait while we analyze your campaign data...
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
