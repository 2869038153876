import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { BaseReport } from '../types/reports';
import { useHistory } from 'react-router-dom';

interface ExtendedCampaignPerformance {
  name: string;
  spend: number;
  sevenDayTotalSales: number;
  sevenDayTotalOrders: number;
  impressions: number;
  clicks: number;
  acos: number;
  roas: number;
  cvr: number;
  ctr: number;
  cpc: number;
}

interface DashboardProps {
  showSearchTermsOnly?: boolean;
  setShowSearchTermsOnly?: (show: boolean) => void;
}

const Dashboard: React.FC<DashboardProps> = ({
  showSearchTermsOnly = false,
  setShowSearchTermsOnly = () => {}
}) => {
  const history = useHistory();
  useState({
    minSpend: 0,
    maxSpend: Infinity,
    minSales: 0,
    maxSales: Infinity,
    minAcos: 0,
    maxAcos: Infinity,
  });

  const { data, activeReportType } = useSelector((state: RootState) => state.campaign);

  const aggregateByName = (data: any[]): any[] => {
    const groupedData = data.reduce((acc: { [key: string]: any }, item: any) => {
      const campaignName = item.campaignName || '';
      if (!acc[campaignName]) {
        acc[campaignName] = {
          campaignName,
          spend: 0,
          sevenDayTotalSales: 0,
          sevenDayTotalOrders: 0,
          clicks: 0,
          impressions: 0
        };
      }
      acc[campaignName].spend += Number(item.spend) || 0;
      acc[campaignName].sevenDayTotalSales += Number(item.sevenDayTotalSales) || 0;
      acc[campaignName].sevenDayTotalOrders += Number(item.sevenDayTotalOrders) || 0;
      acc[campaignName].clicks += Number(item.clicks) || 0;
      acc[campaignName].impressions += Number(item.impressions) || 0;
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const calculateMetrics = (campaign: BaseReport): ExtendedCampaignPerformance => {
    const sales = Number(campaign.sevenDayTotalSales) || 0;
    const spend = Number(campaign.spend) || 0;
    const orders = Number(campaign.sevenDayTotalOrders) || 0;
    const clicks = Number(campaign.clicks) || 0;
    const impressions = Number(campaign.impressions) || 0;

    const acos = sales > 0 ? (spend / sales) * 100 : 0;
    const roas = spend > 0 ? sales / spend : 0;
    const ctr = impressions > 0 ? (clicks / impressions) * 100 : 0;
    const cvr = clicks > 0 ? (orders / clicks) * 100 : 0;
    const cpc = clicks > 0 ? spend / clicks : 0;

    return {
      name: campaign.campaignName || '',
      spend,
      sevenDayTotalSales: sales,
      sevenDayTotalOrders: orders,
      impressions,
      clicks,
      acos,
      roas,
      cvr,
      ctr,
      cpc
    };
  };

  const processedData = useMemo(() => {
    if (!data[activeReportType]) return [];
    const aggregatedData = aggregateByName(data[activeReportType]);
    return aggregatedData.map((campaign: BaseReport) => calculateMetrics(campaign));
  }, [data, activeReportType]);

  const overallMetrics = useMemo(() => {
    const totals = processedData.reduce((acc: Partial<ExtendedCampaignPerformance>, camp: ExtendedCampaignPerformance) => ({
      spend: (acc.spend || 0) + camp.spend,
      sevenDayTotalSales: (acc.sevenDayTotalSales || 0) + camp.sevenDayTotalSales,
      sevenDayTotalOrders: (acc.sevenDayTotalOrders || 0) + camp.sevenDayTotalOrders,
      impressions: (acc.impressions || 0) + camp.impressions,
      clicks: (acc.clicks || 0) + camp.clicks
    }), {});

    const sales = totals.sevenDayTotalSales || 0;
    const spend = totals.spend || 0;
    const orders = totals.sevenDayTotalOrders || 0;
    const clicks = totals.clicks || 0;
    const impressions = totals.impressions || 0;

    const acos = sales > 0 ? (spend / sales) * 100 : 0;
    const roas = spend > 0 ? sales / spend : 0;
    const ctr = impressions > 0 ? (clicks / impressions) * 100 : 0;
    const cvr = clicks > 0 ? (orders / clicks) * 100 : 0;
    const cpc = clicks > 0 ? spend / clicks : 0;

    return {
      spend,
      sales,
      orders,
      impressions,
      clicks,
      acos,
      roas,
      cvr,
      ctr,
      cpc
    };
  }, [processedData]);

  const topPerformingCampaigns = useMemo(() => 
    [...processedData]
      .filter((camp: ExtendedCampaignPerformance) => camp.spend > 0 && camp.sevenDayTotalSales > 0)
      .sort((a: ExtendedCampaignPerformance, b: ExtendedCampaignPerformance) => b.sevenDayTotalSales - a.sevenDayTotalSales)
      .slice(0, 5),
    [processedData]
  );

  const topRoasCampaigns = useMemo(() => 
    [...processedData]
      .filter((camp: ExtendedCampaignPerformance) => camp.spend > 0 && camp.sevenDayTotalSales > 0)
      .sort((a: ExtendedCampaignPerformance, b: ExtendedCampaignPerformance) => b.roas - a.roas)
      .slice(0, 5),
    [processedData]
  );

  const costlyAcosCampaigns = useMemo(() => 
    [...processedData]
      .filter((camp: ExtendedCampaignPerformance) => camp.spend > 50 && camp.sevenDayTotalSales > 0)
      .sort((a: ExtendedCampaignPerformance, b: ExtendedCampaignPerformance) => b.acos - a.acos)
      .slice(0, 5),
    [processedData]
  );

  const formatNumber = (value: number | undefined, type: 'number' | 'currency' | 'decimal' | 'percentage'): string => {
    if (value === undefined || isNaN(value)) return '-';
  
    switch (type) {
      case 'number':
        return Math.round(value).toLocaleString();
      case 'currency':
        return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      case 'decimal':
        return value.toFixed(2);
      case 'percentage':
        return `${value.toFixed(1)}%`;
      default:
        return value.toString();
    }
  };

  const handleCampaignClick = (campaignName: string) => {
    history.push('/search-terms', { 
      campaignName,
      filters: {
        campaignName
      },
      activeTab: 'search-terms'
    });
  };

  return (
    <div className="space-y-6 p-6">
      {/* Main KPIs */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">Total Spend</h3>
          <p className="text-2xl font-semibold">{formatNumber(overallMetrics.spend, 'currency')}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">Total Sales</h3>
          <p className="text-2xl font-semibold">{formatNumber(overallMetrics.sales, 'currency')}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">ACoS</h3>
          <p className="text-2xl font-semibold flex items-center">
            {overallMetrics.acos === 0 ? '⚪️' : 
             overallMetrics.acos < 30 ? '🟢' : 
             overallMetrics.acos < 50 ? '🟡' : '🔴'} {formatNumber(overallMetrics.acos, 'decimal')}%
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">ROAS</h3>
          <p className="text-2xl font-semibold flex items-center">
            {overallMetrics.roas === 0 ? '⚪️' : 
             overallMetrics.roas > 3 ? '🟢' : 
             overallMetrics.roas > 2 ? '🟡' : '🔴'} {formatNumber(overallMetrics.roas, 'decimal')}x
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">Orders</h3>
          <p className="text-2xl font-semibold">{formatNumber(overallMetrics.orders, 'number')}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">Conversion Rate</h3>
          <p className="text-2xl font-semibold flex items-center">
            {overallMetrics.cvr === 0 ? '⚪️' : 
             overallMetrics.cvr > 15 ? '🟢' : 
             overallMetrics.cvr > 10 ? '🟡' : '🔴'} {formatNumber(overallMetrics.cvr, 'decimal')}%
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">Avg CPC</h3>
          <p className="text-2xl font-semibold">{formatNumber(overallMetrics.cpc, 'currency')}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm text-gray-500">Clicks</h3>
          <p className="text-2xl font-semibold">{formatNumber(overallMetrics.clicks, 'number')}</p>
        </div>
      </div>

      {/* Campaign Performance Analysis */}
      <div className="grid grid-cols-1 gap-6">
        {/* Top Performing Campaigns - Full Width */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Top Performing Campaigns</h2>
            <div className="text-sm text-gray-500">
              Click on a campaign to view its search terms
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Campaign</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Spend</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Sales</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Orders</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">ACoS</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">ROAS</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">CVR</th>
                </tr>
              </thead>
              <tbody>
                {topPerformingCampaigns.slice(0, 5).map((campaign: ExtendedCampaignPerformance, index: number) => (
                  <tr 
                    key={index}
                    onClick={() => handleCampaignClick(campaign.name)}
                    className="hover:bg-gray-50 cursor-pointer border-t"
                  >
                    <td className="px-4 py-2 text-sm">{campaign.name}</td>
                    <td className="px-4 py-2 text-sm">{formatNumber(campaign.spend, 'currency')}</td>
                    <td className="px-4 py-2 text-sm">{formatNumber(campaign.sevenDayTotalSales, 'currency')}</td>
                    <td className="px-4 py-2 text-sm">{formatNumber(campaign.sevenDayTotalOrders, 'number')}</td>
                    <td className="px-4 py-2 text-sm">
                      {campaign.acos === 0 ? '⚪️' : 
                       campaign.acos < 30 ? '🟢' : 
                       campaign.acos < 50 ? '🟡' : '🔴'} {formatNumber(campaign.acos, 'decimal')}%
                    </td>
                    <td className="px-4 py-2 text-sm">
                      {campaign.roas === 0 ? '⚪️' : 
                       campaign.roas > 3 ? '🟢' : 
                       campaign.roas > 2 ? '🟡' : '🔴'} {formatNumber(campaign.roas, 'decimal')}
                    </td>
                    <td className="px-4 py-2 text-sm">
                      {campaign.cvr === 0 ? '⚪️' : 
                       campaign.cvr > 15 ? '🟢' : 
                       campaign.cvr > 10 ? '🟡' : '🔴'} {formatNumber(campaign.cvr, 'decimal')}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Campaign Insights - Two Column Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* High ROAS Campaigns */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Best ROAS Campaigns</h3>
            <div className="space-y-3">
              {topRoasCampaigns.slice(0, 5).map((campaign: ExtendedCampaignPerformance, index: number) => (
                <div 
                  key={index} 
                  onClick={() => handleCampaignClick(campaign.name)}
                  className="flex justify-between items-center p-2 hover:bg-gray-50 rounded cursor-pointer border-b"
                >
                  <span className="text-sm font-medium text-gray-900">
                    {campaign.name}
                  </span>
                  <div className="text-right">
                    <span className="text-sm font-medium text-green-600">
                      {campaign.roas === 0 ? '⚪️' : 
                       campaign.roas > 3 ? '🟢' : 
                       campaign.roas > 2 ? '🟡' : '🔴'} {formatNumber(campaign.roas, 'decimal')}x
                    </span>
                    <span className="text-xs text-gray-500 block">
                      Sales: {formatNumber(campaign.sevenDayTotalSales, 'currency')}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* High ACoS Campaigns */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">High ACoS Campaigns</h3>
            <div className="space-y-3">
              {costlyAcosCampaigns.slice(0, 5).map((campaign: ExtendedCampaignPerformance, index: number) => (
                <div 
                  key={index} 
                  onClick={() => handleCampaignClick(campaign.name)}
                  className="flex justify-between items-center p-2 hover:bg-gray-50 rounded cursor-pointer border-b"
                >
                  <span className="text-sm font-medium text-gray-900">
                    {campaign.name}
                  </span>
                  <div className="text-right">
                    <span className="text-sm font-medium text-red-600">
                      {campaign.acos === 0 ? '⚪️' : 
                       campaign.acos < 30 ? '🟢' : 
                       campaign.acos < 50 ? '🟡' : '🔴'} {formatNumber(campaign.acos, 'decimal')}%
                    </span>
                    <span className="text-xs text-gray-500 block">
                      Spend: {formatNumber(campaign.spend, 'currency')}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
