import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { formatNumber } from '../utils/formatters';

interface WastedSpendFilters {
  minSpend: number;
  minClicks: number;
  minImpressions: number;
  minAcos: number;
}

interface WastedSpendKeyword {
  customerSearchTerm: string;
  matchType: string;
  spend: number;
  clicks: number;
  impressions: number;
  ctr: number;
  cpc: number;
  asin: string;
  orders: number;
  sales: number;
  acos: number | null;
}

interface WastedSpendItem {
  campaign: string;
  spend: number;
  clicks: number;
  impressions: number;
  orders: number;
  sales: number;
  keywords: WastedSpendKeyword[];
  ctr: number;
  cvr: number;
  acos: number | null;
  cpc: number;
  roas: number;
}

const defaultFilters: WastedSpendFilters = {
  minSpend: 0,
  minClicks: 0,
  minImpressions: 0,
  minAcos: 50
};

const WastedSpendAnalysis: React.FC = () => {
  const { data, activeReportType } = useSelector((state: RootState) => state.campaign);
  const [filters, setFilters] = useState<WastedSpendFilters>(defaultFilters);
  const [expandedCampaigns, setExpandedCampaigns] = useState<Set<string>>(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [keywordPages, setKeywordPages] = useState<Record<string, number>>({});
  const [keywordSearches, setKeywordSearches] = useState<Record<string, string>>({});
  const [keywordSorts, setKeywordSorts] = useState<Record<string, { field: string; order: 'asc' | 'desc' }>>({});
  const [showZeroOrdersOnly, setShowZeroOrdersOnly] = useState(false);
  const itemsPerPage = 10;
  const keywordsPerPage = 25;

  const getWastedSpendData = (data: any[], showZeroOrdersOnly: boolean, filters: WastedSpendFilters) => {
    return data.reduce((acc: Record<string, WastedSpendItem>, item: any) => {
      const key = item.campaignName;
      const orders = Number(item.sevenDayTotalOrders) || 0;
      const sales = Number(item.sevenDayTotalSales) || 0;
      const spend = Number(item.spend) || 0;
      const clicks = Number(item.clicks) || 0;
      const impressions = Number(item.impressions) || 0;
      const acos = sales > 0 ? (spend / sales) * 100 : null;

      // Skip items that don't meet our filter criteria
      if (showZeroOrdersOnly && orders !== 0) return acc;
      if (filters.minSpend && spend < filters.minSpend) return acc;
      if (filters.minAcos && acos && acos < filters.minAcos) return acc;
      if (filters.minClicks && clicks < filters.minClicks) return acc;
      if (filters.minImpressions && impressions < filters.minImpressions) return acc;

      if (!acc[key]) {
        acc[key] = {
          campaign: key,
          spend: 0,
          clicks: 0,
          impressions: 0,
          orders: 0,
          sales: 0,
          keywords: [],
          ctr: 0,
          cvr: 0,
          acos: 0,
          cpc: 0,
          roas: 0
        };
      }

      acc[key].spend += spend;
      acc[key].clicks += clicks;
      acc[key].impressions += impressions;
      acc[key].sales += sales;
      acc[key].orders += orders;

      if (item.customerSearchTerm) {
        acc[key].keywords.push({
          customerSearchTerm: item.customerSearchTerm,
          matchType: item.matchType || 'Unknown',
          spend,
          clicks,
          impressions,
          ctr: impressions > 0 ? (clicks / impressions) * 100 : 0,
          cpc: clicks > 0 ? spend / clicks : 0,
          asin: item.asin || '',
          orders,
          sales,
          acos
        });
      }

      return acc;
    }, {});
  };

  const calculateTotals = useMemo(() => (data: WastedSpendItem[]) => {
    return data.reduce((acc, campaign) => {
      return {
        spend: acc.spend + campaign.spend,
        clicks: acc.clicks + campaign.clicks,
        impressions: acc.impressions + campaign.impressions,
        orders: acc.orders + campaign.orders,
        sales: acc.sales + campaign.sales,
      };
    }, {
      spend: 0,
      clicks: 0,
      impressions: 0,
      orders: 0,
      sales: 0,
    });
  }, []);

  const wastedSpendData = useMemo(() => {
    if (!data || !data[activeReportType]) return [];

    const campaignMap = getWastedSpendData(data[activeReportType], showZeroOrdersOnly, filters);

    return Object.values(campaignMap)
      .map((campaign) => {
        // Calculate campaign-level metrics
        const metrics = campaign.keywords.reduce((acc, kw) => {
          return {
            spend: acc.spend + kw.spend,
            clicks: acc.clicks + kw.clicks,
            impressions: acc.impressions + kw.impressions,
            orders: acc.orders + kw.orders,
            sales: acc.sales + kw.sales
          };
        }, { spend: 0, clicks: 0, impressions: 0, orders: 0, sales: 0 });

        // Skip campaigns with no spend
        if (metrics.spend === 0) {
          return null;
        }

        const campaignData: WastedSpendItem = {
          campaign: campaign.campaign,
          spend: metrics.spend,
          clicks: metrics.clicks,
          impressions: metrics.impressions,
          orders: metrics.orders,
          sales: metrics.sales,
          keywords: campaign.keywords,
          ctr: metrics.impressions > 0 ? (metrics.clicks / metrics.impressions) * 100 : 0,
          cvr: metrics.clicks > 0 ? (metrics.orders / metrics.clicks) * 100 : 0,
          acos: metrics.sales > 0 ? (metrics.spend / metrics.sales) * 100 : null,
          cpc: metrics.clicks > 0 ? metrics.spend / metrics.clicks : 0,
          roas: metrics.spend > 0 ? metrics.sales / metrics.spend : 0
        };

        return campaignData;
      })
      .filter((campaign): campaign is WastedSpendItem => campaign !== null)
      .sort((a, b) => b.spend - a.spend);
  }, [data, activeReportType, filters, showZeroOrdersOnly]);

  const filteredData = useMemo(() => {
    return wastedSpendData;
  }, [wastedSpendData]);

  const totals = useMemo(() => {
    return calculateTotals(filteredData);
  }, [filteredData, calculateTotals]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [filteredData, currentPage, itemsPerPage]);

  const handleFilterChange = (key: keyof WastedSpendFilters, value: number) => {
    setFilters(prev => ({ ...prev, [key]: value }));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
    setCurrentPage(1);
  };

  const toggleCampaign = (campaign: string) => {
    setExpandedCampaigns(prev => {
      const next = new Set(prev);
      if (next.has(campaign)) {
        next.delete(campaign);
      } else {
        next.add(campaign);
      }
      return next;
    });
  };

  const getFilteredKeywords = (campaign: string, keywords: WastedSpendKeyword[]) => {
    // First filter keywords based on search term if exists
    const searchTerm = keywordSearches[campaign] || '';
    const filteredKeywords = keywords.filter(kw => {
      if (showZeroOrdersOnly && kw.orders !== 0) return false;
      if (searchTerm && !kw.customerSearchTerm.toLowerCase().includes(searchTerm.toLowerCase())) return false;
      return true;
    });

    // Then sort the filtered keywords
    const sort = keywordSorts[campaign] || { field: 'spend', order: 'desc' };
    
    const sortedKeywords = filteredKeywords.sort((a, b) => {
      const getValue = (item: WastedSpendKeyword) => {
        const value = (() => {
          switch (sort.field) {
            case 'spend': return item.spend;
            case 'clicks': return item.clicks;
            case 'impressions': return item.impressions;
            case 'ctr': return item.ctr;
            case 'cpc': return item.cpc;
            case 'orders': return item.orders;
            case 'sales': return item.sales;
            case 'acos': return item.acos ?? Infinity;
            default: return item.spend;
          }
        })();
        return value;
      };
      
      const multiplier = sort.order === 'desc' ? -1 : 1;
      const aValue = getValue(a);
      const bValue = getValue(b);
      return (aValue - bValue) * multiplier;
    });

    // Get the current page for this campaign
    const page = keywordPages[campaign] || 1;
    const start = (page - 1) * keywordsPerPage;
    const end = start + keywordsPerPage;

    return {
      keywords: sortedKeywords.slice(start, end),
      totalKeywords: sortedKeywords.length,
      totalPages: Math.ceil(sortedKeywords.length / keywordsPerPage)
    };
  };

  const handleSort = (campaign: string, field: string) => {
    setKeywordSorts(prev => {
      const currentSort = prev[campaign] || { field: 'spend', order: 'desc' };
      const newOrder = currentSort.field === field && currentSort.order === 'desc' ? 'asc' : 'desc';
      return {
        ...prev,
        [campaign]: { field, order: newOrder }
      };
    });
  };

  const getMatchTypeColor = (matchType: string) => {
    switch (matchType.toLowerCase()) {
      case 'broad':
        return 'text-blue-600';
      case 'phrase':
        return 'text-green-600';
      case 'exact':
        return 'text-purple-600';
      case 'auto':
        return 'text-orange-600';
      default:
        return 'text-gray-600';
    }
  };

  const SortableHeader = ({ campaign, field, label }: { campaign: string; field: string; label: string }) => {
    const sort = keywordSorts[campaign] || { field: 'spend', order: 'desc' };
    const isActive = sort.field === field;
    
    return (
      <th 
        className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
        onClick={() => handleSort(campaign, field)}
      >
        <div className="flex items-center justify-between">
          <span>{label}</span>
          {isActive && (
            <span className="ml-1">
              {sort.order === 'desc' ? '↓' : '↑'}
            </span>
          )}
        </div>
      </th>
    );
  };

  return (
    <div className="p-6">
      {/* Summary Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
        {[
          {
            label: 'Total Wasted Spend',
            value: formatNumber(totals.spend, 'currency'),
            color: 'text-red-600'
          },
          {
            label: 'Total Sales',
            value: formatNumber(totals.sales, 'currency'),
            color: 'text-green-600'
          },
          {
            label: 'Total Orders',
            value: formatNumber(totals.orders, 'number'),
            color: 'text-blue-600'
          },
          {
            label: 'ACoS',
            value: totals.sales > 0 ? `${((totals.spend / totals.sales) * 100).toFixed(1)}%` : 'N/A',
            color: 'text-purple-600'
          },
          {
            label: 'CTR',
            value: totals.impressions > 0 ? `${((totals.clicks / totals.impressions) * 100).toFixed(1)}%` : '0%',
            color: 'text-gray-600'
          }
        ].map((stat, index) => (
          <div key={index} className="bg-white rounded-lg shadow p-4">
            <h3 className="text-sm font-medium text-gray-500">{stat.label}</h3>
            <p className={`text-2xl font-semibold ${stat.color}`}>{stat.value}</p>
          </div>
        ))}
      </div>

      {/* Filter Banner */}
      <div className="mb-6 bg-white rounded-lg shadow p-4">
        <h2 className="text-lg font-semibold mb-4">Wasted Spend Filters</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label className="block text-sm text-gray-600 mb-1">Min Spend ($)</label>
            <input
              type="number"
              value={filters.minSpend || ''}
              onChange={(e) => handleFilterChange('minSpend', e.target.value === '' ? 0 : Number(e.target.value))}
              className="w-full p-2 border rounded"
              min="0"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Min ACoS (%)</label>
            <input
              type="number"
              value={filters.minAcos || ''}
              onChange={(e) => handleFilterChange('minAcos', e.target.value === '' ? 0 : Number(e.target.value))}
              className="w-full p-2 border rounded"
              min="0"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Min Clicks</label>
            <input
              type="number"
              value={filters.minClicks || ''}
              onChange={(e) => handleFilterChange('minClicks', e.target.value === '' ? 0 : Number(e.target.value))}
              className="w-full p-2 border rounded"
              min="0"
            />
          </div>
          <div>
            <label className="block text-sm text-gray-600 mb-1">Show Keywords</label>
            <div className="flex items-center mt-2">
              <input
                type="checkbox"
                checked={showZeroOrdersOnly}
                onChange={(e) => setShowZeroOrdersOnly(e.target.checked)}
                className="form-checkbox h-4 w-4 text-blue-600"
              />
              <span className="ml-2 text-sm text-gray-700">Zero Orders Only</span>
            </div>
          </div>
        </div>
      </div>

      {/* Active Filters */}
      {Object.entries(filters).some(([key, value]) => value > 0) && (
        <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 mb-6">
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm text-gray-600">Active Filters:</span>
            <button
              onClick={clearFilters}
              className="text-sm text-blue-600 hover:text-blue-800"
            >
              Clear All
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {Object.entries(filters).map(([key, value]) => {
              if (value === 0) return null;
              const label = key === 'minAcos' ? 'Min ACoS' :
                           key === 'minSpend' ? 'Min Spend' :
                           key === 'minClicks' ? 'Min Clicks' :
                           'Min Impressions';
              return (
                <div
                  key={key}
                  className="bg-white px-3 py-1 rounded-full text-sm flex items-center gap-2"
                >
                  <span>{label}: {key.includes('Acos') ? `${value}%` : value}</span>
                  <button
                    onClick={() => handleFilterChange(key as keyof WastedSpendFilters, 0)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    ×
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Wasted Spend List */}
      <div className="space-y-4">
        <table className="min-w-full">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Spend</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Sales</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Orders</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ACoS</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Clicks</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">CTR</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">CPC</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">CVR</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ROAS</th>
              <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Keywords</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedData.map((campaign) => (
              <Fragment key={campaign.campaign}>
                {/* Campaign Row */}
                <tr className={`${expandedCampaigns.has(campaign.campaign) ? 'bg-blue-50' : 'hover:bg-gray-50'}`}>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => toggleCampaign(campaign.campaign)}
                      className="flex items-center space-x-2 text-left"
                    >
                      <span className={`transform transition-transform ${expandedCampaigns.has(campaign.campaign) ? 'rotate-90' : ''}`}>
                        ▶
                      </span>
                      <span className="font-medium">{campaign.campaign}</span>
                    </button>
                  </td>
                  <td className="px-4 py-2 text-right text-red-600 font-medium">${campaign.spend.toFixed(2)}</td>
                  <td className="px-4 py-2 text-right">${campaign.sales.toFixed(2)}</td>
                  <td className="px-4 py-2 text-right">{campaign.orders}</td>
                  <td className="px-4 py-2 text-right">
                    {campaign.acos !== null ? `${campaign.acos.toFixed(1)}%` : 'N/A'}
                  </td>
                  <td className="px-4 py-2 text-right">{campaign.clicks}</td>
                  <td className="px-4 py-2 text-right">{campaign.ctr.toFixed(1)}%</td>
                  <td className="px-4 py-2 text-right">${campaign.cpc.toFixed(2)}</td>
                  <td className="px-4 py-2 text-right">{campaign.cvr.toFixed(1)}%</td>
                  <td className="px-4 py-2 text-right">{campaign.roas.toFixed(2)}x</td>
                  <td className="px-4 py-2 text-right">{campaign.keywords.length}</td>
                </tr>
                {/* Keywords Table */}
                {expandedCampaigns.has(campaign.campaign) && (
                  <tr>
                    <td colSpan={11} className="bg-gray-50 px-4 py-4">
                      <div className="mb-4 flex justify-between items-center">
                        <div className="flex-1 max-w-xs">
                          <input
                            type="text"
                            placeholder="Search keywords..."
                            value={keywordSearches[campaign.campaign] || ''}
                            onChange={(e) => {
                              setKeywordSearches(prev => ({
                                ...prev,
                                [campaign.campaign]: e.target.value
                              }));
                              setKeywordPages(prev => ({
                                ...prev,
                                [campaign.campaign]: 1
                              }));
                            }}
                            className="w-full p-2 border rounded"
                          />
                        </div>
                      </div>
                      <div className="overflow-x-auto">
                        <table className="min-w-full">
                          <thead>
                            <tr className="text-sm text-gray-500">
                              <th className="px-4 py-2 text-left">Search Term</th>
                              <th className="px-4 py-2 text-left">Match Type</th>
                              <SortableHeader campaign={campaign.campaign} field="spend" label="Spend" />
                              <SortableHeader campaign={campaign.campaign} field="sales" label="Sales" />
                              <SortableHeader campaign={campaign.campaign} field="orders" label="Orders" />
                              <SortableHeader campaign={campaign.campaign} field="acos" label="ACoS" />
                              <SortableHeader campaign={campaign.campaign} field="clicks" label="Clicks" />
                              <SortableHeader campaign={campaign.campaign} field="ctr" label="CTR" />
                              <SortableHeader campaign={campaign.campaign} field="cpc" label="CPC" />
                            </tr>
                          </thead>
                          <tbody>
                            {(() => {
                              const { keywords, totalKeywords, totalPages } = getFilteredKeywords(campaign.campaign, campaign.keywords);
                              return (
                                <>
                                  {keywords.map((keyword, idx) => (
                                    <tr key={idx} className="hover:bg-gray-100">
                                      <td className="px-4 py-2 text-sm">{keyword.customerSearchTerm}</td>
                                      <td className="px-4 py-2 text-sm">
                                        <span className={`font-medium ${getMatchTypeColor(keyword.matchType)}`}>
                                          {keyword.matchType}
                                        </span>
                                      </td>
                                      <td className="px-4 py-2 text-sm text-right">${keyword.spend.toFixed(2)}</td>
                                      <td className="px-4 py-2 text-sm text-right">${keyword.sales.toFixed(2)}</td>
                                      <td className="px-4 py-2 text-sm text-right">{keyword.orders}</td>
                                      <td className="px-4 py-2 text-sm text-right">
                                        {keyword.acos !== null ? `${keyword.acos.toFixed(1)}%` : 'N/A'}
                                      </td>
                                      <td className="px-4 py-2 text-sm text-right">{keyword.clicks}</td>
                                      <td className="px-4 py-2 text-sm text-right">{keyword.ctr.toFixed(1)}%</td>
                                      <td className="px-4 py-2 text-sm text-right">${keyword.cpc.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                  {totalPages > 1 && (
                                    <tr>
                                      <td colSpan={9} className="px-4 py-4">
                                        <div className="flex justify-between items-center">
                                          <span className="text-sm text-gray-600">
                                            Showing {((keywordPages[campaign.campaign] || 1) - 1) * keywordsPerPage + 1} to{' '}
                                            {Math.min((keywordPages[campaign.campaign] || 1) * keywordsPerPage, totalKeywords)} of {totalKeywords} keywords
                                          </span>
                                          <div className="flex space-x-2">
                                            <button
                                              onClick={() => setKeywordPages(prev => ({
                                                ...prev,
                                                [campaign.campaign]: Math.max(1, (prev[campaign.campaign] || 1) - 1)
                                              }))}
                                              disabled={(keywordPages[campaign.campaign] || 1) === 1}
                                              className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
                                            >
                                              Previous
                                            </button>
                                            <button
                                              onClick={() => setKeywordPages(prev => ({
                                                ...prev,
                                                [campaign.campaign]: Math.min(totalPages, (prev[campaign.campaign] || 1) + 1)
                                              }))}
                                              disabled={(keywordPages[campaign.campaign] || 1) === totalPages}
                                              className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
                                            >
                                              Next
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            })()}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
            {filteredData.length === 0 && (
              <div className="text-center text-gray-500 py-8">
                No wasted spend data found with current filters
              </div>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center items-center gap-4 mt-6">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default WastedSpendAnalysis;
