export type FormatType = 'number' | 'currency' | 'decimal' | 'percentage';

const formatters = {
  currency: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }),
  number: new Intl.NumberFormat('en-US'),
  decimal: new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }),
  percentage: new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  })
};

/**
 * Safely formats a number with proper handling of undefined, null, and NaN values.
 * @param value The number to format
 * @param type The type of formatting to apply
 * @returns A formatted string representation of the number
 */
export const formatNumber = (value: number | undefined | null, type: FormatType = 'number'): string => {
  // Handle invalid values
  if (value === undefined || value === null || Number.isNaN(value)) {
    return '-';
  }

  // Convert to number if it's not already
  const num = Number(value);
  if (Number.isNaN(num)) {
    return '-';
  }

  try {
    switch (type) {
      case 'currency':
        return formatters.currency.format(num);
      case 'percentage':
        return `${formatters.percentage.format(num)}%`;
      case 'decimal':
        return formatters.decimal.format(num);
      case 'number':
        return formatters.number.format(num);
      default:
        return formatters.number.format(num);
    }
  } catch (error) {
    console.error('Error formatting number:', { value, type, error });
    return '-';
  }
};
