import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  BarController,
  BubbleController,
  ChartData
} from 'chart.js';
import { Bar, Bubble } from 'react-chartjs-2';
import { RootState } from '../store/store';

// Register ALL Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BubbleController
);

interface Props {
  showSearchTermsOnly?: boolean;
  setShowSearchTermsOnly?: (value: boolean) => void;
}

type MetricType = 'campaignName' | 'spend' | 'sales' | 'acos' | 'roas' | 'orders' | 'cvr' | 'ctr' | 'cvr' | 'impressions' | 'clicks' | 'orders' | 'cpc' | 'name';

interface SearchTermFilters {
  searchTerm: string;
  minImpressions: string;
  minClicks: string;
  minCtr: string;
  minSpend: string;
  minSales: string;
  minOrders: string;
  minConversionRate: string;
  maxAcos: string;
  minRoas: string;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  campaignName: string;
  totalOrders: string;
}

interface ActiveFilter {
  key: keyof SearchTermFilters;
  label: string;
  value: string;
}

interface LocationState {
  campaignName?: string;
  filters?: {
    campaignName: string;
  };
  activeTab?: string;
}

const initialFilters: SearchTermFilters = {
  searchTerm: '',
  minImpressions: '',
  minClicks: '',
  minCtr: '',
  minSpend: '',
  minSales: '',
  minOrders: '',
  minConversionRate: '',
  maxAcos: '',
  minRoas: '',
  sortBy: 'spend',
  sortOrder: 'desc',
  campaignName: '',
  totalOrders: ''
};

interface FilterItem {
  placeholder: string;
  value: keyof SearchTermFilters;
}

const filterInputs: FilterItem[] = [
  { placeholder: "Min Impressions", value: "minImpressions" },
  { placeholder: "Min Clicks", value: "minClicks" },
  { placeholder: "Min CTR %", value: "minCtr" },
  { placeholder: "Min Spend $", value: "minSpend" },
  { placeholder: "Min Sales $", value: "minSales" },
  { placeholder: "Min Orders", value: "minOrders" },
  { placeholder: "Min CVR %", value: "minConversionRate" },
  { placeholder: "Max ACoS %", value: "maxAcos" },
  { placeholder: "Min ROAS", value: "minRoas" },
  { placeholder: "Total Orders", value: "totalOrders" }
];

const aggregateByName = (data: any[]): any[] => {
  const groupedData = data.reduce((acc: { [key: string]: any }, item: any) => {
    const campaignName = (item.campaignName || '').trim();
    const key = campaignName.toLowerCase();
    
    if (!acc[key]) {
      acc[key] = {
        campaignName,
        spend: 0,
        sevenDayTotalSales: 0,
        sevenDayTotalOrders: 0,
        clicks: 0,
        impressions: 0
      };
    }
    
    acc[key].spend += Number(item.spend) || 0;
    acc[key].sevenDayTotalSales += Number(item.sevenDayTotalSales) || 0;
    acc[key].sevenDayTotalOrders += Number(item.sevenDayTotalOrders) || 0;
    acc[key].clicks += Number(item.clicks) || 0;
    acc[key].impressions += Number(item.impressions) || 0;
    
    return acc;
  }, {});

  return Object.values(groupedData);
};

const CampaignAnalysis: React.FC<Props> = ({
  showSearchTermsOnly = false,
  setShowSearchTermsOnly = () => {}
}) => {
  const dispatch = useSelector((state: RootState) => state.campaign);
  const { data, activeReportType } = dispatch;
  
  const aggregatedData = useMemo(() => {
    return aggregateByName(data[activeReportType] || []);
  }, [data, activeReportType]);

  const processedData = useMemo(() => {
    return aggregatedData.map((item: any) => {
      const sales = Number(item.sevenDayTotalSales) || 0;
      const spend = Number(item.spend) || 0;
      const orders = Number(item.sevenDayTotalOrders) || 0;
      const clicks = Number(item.clicks) || 0;
      const impressions = Number(item.impressions) || 0;
      
      return {
        ...item,
        campaignName: item.campaignName || '',
        sales,
        spend,
        orders,
        clicks,
        impressions,
        ctr: impressions > 0 ? (clicks / impressions) * 100 : 0,
        acos: sales > 0 ? (spend / sales) * 100 : 0,
        roas: spend > 0 ? sales / spend : 0,
        cvr: clicks > 0 ? (orders / clicks) * 100 : 0,
        cpc: clicks > 0 ? spend / clicks : 0
      };
    });
  }, [aggregatedData]);

  const [searchTermFilters, setSearchTermFilters] = useState<SearchTermFilters>(initialFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<MetricType>('sales');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const itemsPerPage = 25;

  const history = useHistory();
  const location = useLocation<LocationState>();
  const { campaignName, filters: locationFilters } = location.state || {};

  const handleSearchTermFilterChange = (field: keyof SearchTermFilters, value: string) => {
    setSearchTermFilters(prev => ({
      ...prev,
      [field]: value
    }));
    setCurrentPage(1);
  };

  const formatNumber = (value: number | undefined, type: 'number' | 'currency' | 'decimal' | 'percentage'): string => {
    if (value === undefined || isNaN(value)) return '-';
    
    switch (type) {
      case 'number':
        return Math.round(value).toLocaleString();
      case 'currency':
        return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      case 'decimal':
        return value.toFixed(2);
      case 'percentage':
        return `${value.toFixed(1)}%`;
      default:
        return value.toString();
    }
  };

  const filteredData = useMemo(() => {
    const campaignMap = new Map<string, any>();
    
    processedData.forEach((item: any) => {
      const campaignName = item.campaignName;
      if (!campaignName) return;

      const sales = Number(item.sevenDayTotalSales) || 0;
      const spend = Number(item.spend) || 0;
      const orders = Number(item.sevenDayTotalOrders) || 0;
      const clicks = Number(item.clicks) || 0;
      const impressions = Number(item.impressions) || 0;

      if (!campaignMap.has(campaignName)) {
        campaignMap.set(campaignName, {
          campaignName,
          sales: 0,
          spend: 0,
          orders: 0,
          clicks: 0,
          impressions: 0,
          ctr: 0,
          acos: 0,
          roas: 0,
          cvr: 0
        });
      }

      const campaign = campaignMap.get(campaignName);
      campaign.sales += sales;
      campaign.spend += spend;
      campaign.orders += orders;
      campaign.clicks += clicks;
      campaign.impressions += impressions;
    });

    const aggregatedData = Array.from(campaignMap.values()).map(campaign => {
      return {
        ...campaign,
        ctr: campaign.impressions > 0 ? (campaign.clicks / campaign.impressions) * 100 : 0,
        acos: campaign.sales > 0 ? (campaign.spend / campaign.sales) * 100 : 0,
        roas: campaign.spend > 0 ? campaign.sales / campaign.spend : 0,
        cvr: campaign.clicks > 0 ? (campaign.orders / campaign.clicks) * 100 : 0
      };
    });

    return aggregatedData.filter((campaign: any) => {
      const campaignNameMatch = !searchTermFilters.campaignName || 
        campaign.campaignName.toLowerCase().includes(searchTermFilters.campaignName.toLowerCase());
      
      const meetsMinimums = (
        (!searchTermFilters.minSpend || campaign.spend >= parseFloat(searchTermFilters.minSpend)) &&
        (!searchTermFilters.minSales || campaign.sales >= parseFloat(searchTermFilters.minSales)) &&
        (!searchTermFilters.minOrders || campaign.orders >= parseFloat(searchTermFilters.minOrders)) &&
        (!searchTermFilters.maxAcos || campaign.acos <= parseFloat(searchTermFilters.maxAcos)) &&
        (!searchTermFilters.minRoas || campaign.roas >= parseFloat(searchTermFilters.minRoas)) &&
        (!searchTermFilters.minConversionRate || campaign.cvr >= parseFloat(searchTermFilters.minConversionRate)) &&
        (!searchTermFilters.minImpressions || campaign.impressions >= parseFloat(searchTermFilters.minImpressions)) &&
        (!searchTermFilters.minClicks || campaign.clicks >= parseFloat(searchTermFilters.minClicks)) &&
        (!searchTermFilters.minCtr || campaign.ctr >= parseFloat(searchTermFilters.minCtr)) &&
        (!searchTermFilters.totalOrders || campaign.orders === parseFloat(searchTermFilters.totalOrders))
      );

      return campaignNameMatch && meetsMinimums;
    });
  }, [processedData, searchTermFilters]);

  const overallMetrics = useMemo(() => {
    const totals = filteredData.reduce((acc, campaign) => ({
      spend: acc.spend + (Number(campaign.spend) || 0),
      sales: acc.sales + (Number(campaign.sales) || 0),
      orders: acc.orders + (Number(campaign.orders) || 0),
      impressions: acc.impressions + (Number(campaign.impressions) || 0),
      clicks: acc.clicks + (Number(campaign.clicks) || 0)
    }), {
      spend: 0,
      sales: 0,
      orders: 0,
      impressions: 0,
      clicks: 0
    });

    const acos = totals.sales > 0 ? (totals.spend / totals.sales) * 100 : 0;
    const roas = totals.spend > 0 ? totals.sales / totals.spend : 0;
    const ctr = totals.impressions > 0 ? (totals.clicks / totals.impressions) * 100 : 0;
    const cvr = totals.clicks > 0 ? (totals.orders / totals.clicks) * 100 : 0;

    return {
      ...totals,
      acos,
      roas,
      ctr,
      cvr
    };
  }, [filteredData]);

  const performanceChartData = useMemo<ChartData<'bar', number[], string>>(() => {
    const top10 = [...filteredData]
      .sort((a, b) => b.sales - a.sales)
      .slice(0, 10);
    
    return {
      labels: top10.map(campaign => campaign.campaignName),
      datasets: [
        {
          type: 'bar' as const,
          label: 'Sales',
          data: top10.map(campaign => campaign.sales),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          borderColor: 'rgb(53, 162, 235)',
          borderWidth: 1,
          yAxisID: 'y'
        },
        {
          type: 'bar' as const,
          label: 'Spend',
          data: top10.map(campaign => campaign.spend),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
          yAxisID: 'y'
        }
      ]
    };
  }, [filteredData]);

  const scatterChartData = useMemo<ChartData<'bubble'>>(() => {
    return {
      datasets: [{
        type: 'bubble' as const,
        label: 'Campaign Performance',
        data: filteredData.map(campaign => ({
          x: campaign.acos,
          y: campaign.sales,
          r: Math.sqrt(campaign.spend) / 2
        })),
        backgroundColor: filteredData.map(campaign => {
          if (campaign.acos < 30) return 'rgba(75, 192, 192, 0.6)';
          if (campaign.acos < 50) return 'rgba(255, 206, 86, 0.6)';
          return 'rgba(255, 99, 132, 0.6)';
        }),
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        hoverRadius: 8,
        hoverBorderWidth: 2
      }]
    };
  }, [filteredData]);

  const performanceChartOptions = useMemo<ChartOptions<'bar'>>(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Top 10 Campaigns by Sales'
      },
      tooltip: {
        callbacks: {
          title(tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const campaign = filteredData[index];
            return campaign?.campaignName || '';
          },
          label(context) {
            const value = context.parsed.y;
            return `${context.dataset.label}: ${formatNumber(value, 'currency')}`;
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Amount ($)'
        },
        ticks: {
          callback(value) {
            return `$${Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          }
        }
      }
    }
  }), [filteredData]);

  const bubbleChartOptions = useMemo<ChartOptions<'bubble'>>(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Campaign ACoS vs Sales (bubble size = spend)'
      },
      tooltip: {
        callbacks: {
          label(context) {
            const campaign = filteredData[context.dataIndex];
            return [
              `Campaign: ${campaign.campaignName}`,
              `ACoS: ${formatNumber(campaign.acos, 'decimal')}%`,
              `Sales: ${formatNumber(campaign.sales, 'currency')}`,
              `Spend: ${formatNumber(campaign.spend, 'currency')}`
            ];
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'ACoS %'
        },
        ticks: {
          callback(value) {
            return `${Number(value).toFixed(1)}%`;
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Sales ($)'
        },
        ticks: {
          callback(value) {
            return `$${Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
          }
        }
      }
    }
  }), [filteredData]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      const multiplier = sortDirection === 'asc' ? 1 : -1;
      
      if (sortField === 'campaignName') {
        return multiplier * a.campaignName.localeCompare(b.campaignName);
      }
      
      const aValue = Number(a[sortField]) || 0;
      const bValue = Number(b[sortField]) || 0;
      return multiplier * (aValue - bValue);
    });
  }, [filteredData, sortField, sortDirection]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedData, currentPage, itemsPerPage]);

  const handleCampaignClick = (campaignName: string) => {
    history.push('/search-terms', { 
      campaignName,
      filters: {
        campaignName
      },
      activeTab: 'search-terms'
    });
  };

  useEffect(() => {
    if (locationFilters?.campaignName || campaignName) {
      const newCampaignName = locationFilters?.campaignName || campaignName || '';
      setSearchTermFilters(prev => ({
        ...prev,
        campaignName: newCampaignName
      }));
    }
  }, [locationFilters, campaignName]);

  const getActiveFilters = (): ActiveFilter[] => {
    return Object.entries(searchTermFilters)
      .filter(([key, value]) => {
        if (key === 'campaignName') {
          return value && (value === locationFilters?.campaignName || value === campaignName);
        }
        return value && key !== 'searchTerm' && !['sortBy', 'sortOrder'].includes(key);
      })
      .map(([key, value]) => ({
        key: key as keyof SearchTermFilters,
        label: filterInputs.find(f => f.value === key)?.placeholder || key,
        value: value.toString()
      }));
  };

  const handleCampaignInputChange = (value: string) => {
    setSearchTermFilters(prev => ({ ...prev, campaignName: value }));
  };

  const clearAllFilters = () => {
    setSearchTermFilters(initialFilters);
  };

  const clearCampaignFilter = () => {
    setSearchTermFilters(prev => ({
      ...prev,
      campaignName: ''
    }));
    history.replace({ ...location, state: {} });
  };

  const handleRemoveFilter = (key: keyof SearchTermFilters) => {
    if (key === 'campaignName') {
      clearCampaignFilter();
    } else {
      setSearchTermFilters(prev => ({
        ...prev,
        [key]: ''
      }));
    }
  };

  const [selectedKPIs, setSelectedKPIs] = useState([
    'impressions',
    'clicks',
    'ctr',
    'spend',
    'sales',
    'acos',
    'roas',
    'orders',
    'cvr'
  ]);

  const availableKPIs = [
    { id: 'impressions', label: 'Impressions', icon: '👁️' },
    { id: 'clicks', label: 'Clicks', icon: '🖱️' },
    { id: 'ctr', label: 'CTR', icon: '📊' },
    { id: 'spend', label: 'Spend', icon: '💰' },
    { id: 'sales', label: 'Sales', icon: '💵' },
    { id: 'acos', label: 'ACoS', icon: '📉' },
    { id: 'roas', label: 'ROAS', icon: '📈' },
    { id: 'orders', label: 'Orders', icon: '📦' },
    { id: 'cvr', label: 'CVR', icon: '🎯' }
  ];

  const handleKPIToggle = (kpiId: string) => {
    if (selectedKPIs.includes(kpiId)) {
      if (selectedKPIs.length > 4) {
        setSelectedKPIs(selectedKPIs.filter(id => id !== kpiId));
      }
    } else if (selectedKPIs.length < 9) {
      setSelectedKPIs([...selectedKPIs, kpiId]);
    }
  };

  const renderKPIValue = (kpi: string, value: number) => {
    const formatType = kpi === 'spend' || kpi === 'sales' ? 'currency' :
                      kpi === 'impressions' || kpi === 'clicks' || kpi === 'orders' ? 'number' :
                      'decimal';
    
    const getStatusEmoji = (kpi: string, value: number) => {
      if (value === 0) return '⚪️';
      switch (kpi) {
        case 'ctr':
          return value > 1 ? '🟢' : value > 0.5 ? '🟡' : '🔴';
        case 'acos':
          return value < 30 ? '🟢' : value < 50 ? '🟡' : '🔴';
        case 'roas':
          return value > 3 ? '🟢' : value > 2 ? '🟡' : '🔴';
        case 'cvr':
          return value > 15 ? '🟢' : value > 10 ? '🟡' : '🔴';
        default:
          return '';
      }
    };

    const formattedValue = formatNumber(value, formatType);
    const emoji = getStatusEmoji(kpi, value);
    const needsPercentage = ['ctr', 'acos', 'cvr'].includes(kpi);
    
    return (
      <div className="text-lg font-semibold flex items-center">
        {emoji && <span className="mr-1">{emoji}</span>}
        {formattedValue}{needsPercentage ? '%' : ''}
      </div>
    );
  };

  const [isKPISettingsOpen, setIsKPISettingsOpen] = useState(false);

  return (
    <div className="p-6 space-y-6">
      {/* Active Filters Banner */}
      <div className="bg-white rounded-lg shadow p-4 mb-4">
        <div className="flex flex-wrap gap-4 items-center">
          <div className="flex-1 min-w-[200px]">
            <input
              type="text"
              value={searchTermFilters.campaignName}
              onChange={(e) => handleCampaignInputChange(e.target.value)}
              placeholder="Filter by campaign name..."
              className="w-full px-3 py-2 border rounded text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-wrap gap-2 items-center">
            {getActiveFilters().map(filter => (
              <span
                key={filter.key}
                className="inline-flex items-center bg-blue-50 px-2 py-1 rounded-md text-sm"
              >
                {filter.label}: {filter.value}
                <button
                  onClick={() => handleRemoveFilter(filter.key)}
                  className="ml-2 text-blue-500 hover:text-blue-700"
                >
                  ×
                </button>
              </span>
            ))}
            {(getActiveFilters().length > 0 || searchTermFilters.campaignName) && (
              <button
                onClick={clearAllFilters}
                className="text-blue-600 hover:text-blue-800 text-sm"
              >
                Clear All
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Filter Section - More compact */}
      <div className="mb-4">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3">
          {filterInputs.map((filter, index) => (
            <div key={index}>
              <input
                type="number"
                min="0"
                placeholder={filter.placeholder}
                value={searchTermFilters[filter.value] || ''}
                onChange={(e) => handleSearchTermFilterChange(filter.value, e.target.value)}
                className="w-full p-1.5 text-sm border rounded bg-gray-50 hover:bg-white focus:bg-white
                 border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500
                 placeholder-gray-400 transition-colors duration-200"
              />
            </div>
          ))}
        </div>
      </div>

      {/* KPI Settings */}
      <div className="mb-4 flex justify-end">
        <div className="relative">
          <button
            onClick={() => setIsKPISettingsOpen(!isKPISettingsOpen)}
            className="px-3 py-1.5 text-sm bg-white border rounded-md shadow hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            ⚙️ Customize KPIs
          </button>
          {isKPISettingsOpen && (
            <div className="absolute right-0 mt-2 w-64 bg-white border rounded-lg shadow-lg z-10 p-4">
              <div className="text-sm font-medium mb-2">Select KPIs (4-9)</div>
              <div className="space-y-2">
                {availableKPIs.map(kpi => (
                  <label key={kpi.id} className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={selectedKPIs.includes(kpi.id)}
                      onChange={() => handleKPIToggle(kpi.id)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span>{kpi.icon} {kpi.label}</span>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* KPI row above charts */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-9 gap-3 mb-4 bg-white p-4 rounded-lg shadow">
        {selectedKPIs.map(kpiId => {
          const kpi = availableKPIs.find(k => k.id === kpiId)!;
          return (
            <div key={kpiId}>
              <div className="text-xs text-gray-500">{kpi.label}</div>
              {renderKPIValue(kpiId, overallMetrics[kpiId])}
            </div>
          );
        })}
      </div>

      {/* Charts section with filter controls */}
      <div className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold">Campaign Performance</h2>
          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-500">
              {filteredData.length} {filteredData.length === 1 ? 'campaign' : 'campaigns'} found
            </span>
            {(getActiveFilters().length > 0 || searchTermFilters.campaignName) && (
              <button
                onClick={clearAllFilters}
                className="text-blue-600 hover:text-blue-800 text-sm flex items-center gap-1"
              >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Clear All Filters
              </button>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white p-4 rounded-lg shadow" style={{ height: '400px' }}>
            <Bar 
              data={performanceChartData} 
              options={performanceChartOptions}
            />
          </div>
          <div className="bg-white p-4 rounded-lg shadow" style={{ height: '400px' }}>
            <Bubble
              data={scatterChartData} 
              options={bubbleChartOptions}
            />
          </div>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <label className="text-sm text-gray-600">Sort by:</label>
              <select
                value={sortField}
                onChange={(e) => setSortField(e.target.value as MetricType)}
                className="p-1.5 text-sm border rounded bg-gray-50 hover:bg-white focus:bg-white
                 border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              >
                <option value="customerSearchTerm">Search Term</option>
                <option value="campaignName">Campaign</option>
                <option value="spend">Spend</option>
                <option value="sales">Sales</option>
                <option value="acos">ACoS</option>
                <option value="roas">ROAS</option>
                <option value="orders">Orders</option>
                <option value="cvr">CVR</option>
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className="text-sm text-gray-600">Order:</label>
              <select
                value={sortDirection}
                onChange={(e) => setSortDirection(e.target.value as 'asc' | 'desc')}
                className="p-1.5 text-sm border rounded bg-gray-50 hover:bg-white focus:bg-white
                 border-gray-300 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Campaign List */}
      <div className="bg-white rounded-lg shadow p-4">
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-50">
                <th className="p-3 text-left text-sm font-semibold text-gray-600">
                  Campaign Name
                </th>
                {selectedKPIs.map((kpi) => (
                  <th key={kpi} className="p-3 text-left text-sm font-semibold text-gray-600">
                    {kpi.toUpperCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((campaign, index) => (
                <tr 
                  key={index} 
                  className="hover:bg-gray-50 border-t cursor-pointer"
                  onClick={() => handleCampaignClick(campaign.campaignName)}
                >
                  <td className="p-3 text-left whitespace-nowrap">
                    <div className="text-sm text-blue-600 hover:text-blue-800">
                      {campaign.campaignName}
                    </div>
                  </td>
                  {selectedKPIs.map((kpi) => (
                    <td key={kpi} className="p-3 text-left whitespace-nowrap">
                      <div className="text-sm">
                        {kpi.toLowerCase() === 'acos' ? (
                          <>
                            {campaign.acos === 0 ? '⚪️' : 
                             campaign.acos < 30 ? '🟢' : 
                             campaign.acos < 50 ? '🟡' : '🔴'} {formatNumber(campaign.acos, 'decimal')}%
                          </>
                        ) : kpi.toLowerCase() === 'roas' ? (
                          <>
                            {campaign.roas === 0 ? '⚪️' : 
                             campaign.roas > 3 ? '🟢' : 
                             campaign.roas > 2 ? '🟡' : '🔴'} {formatNumber(campaign.roas, 'decimal')}
                          </>
                        ) : kpi.toLowerCase() === 'cvr' ? (
                          <>
                            {campaign.cvr === 0 ? '⚪️' : 
                             campaign.cvr > 15 ? '🟢' : 
                             campaign.cvr > 10 ? '🟡' : '🔴'} {formatNumber(campaign.cvr, 'decimal')}%
                          </>
                        ) : kpi.toLowerCase() === 'ctr' ? (
                          <>{formatNumber(campaign[kpi.toLowerCase()], 'decimal')}%</>
                        ) : kpi.toLowerCase() === 'spend' || kpi.toLowerCase() === 'sales' ? (
                          <>{formatNumber(campaign[kpi.toLowerCase()], 'currency')}</>
                        ) : (
                          <>{formatNumber(campaign[kpi.toLowerCase()], 'number')}</>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination at bottom */}
      <div className="flex justify-between items-center mt-4 pt-4 border-t">
        <div className="text-sm text-gray-500">
          Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, filteredData.length)} of {filteredData.length} results
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="text-gray-600">
            Page {currentPage} of {Math.ceil(filteredData.length / itemsPerPage)}
          </span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(Math.ceil(filteredData.length / itemsPerPage), prev + 1))}
            disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}
            className="px-3 py-1 border rounded hover:bg-gray-100 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
      {/* Add click hint */}
      <div className="text-sm text-gray-500 mt-2 text-center">
        Click on a campaign to view its search terms breakdown
      </div>
    </div>
  );
};

export default CampaignAnalysis;
